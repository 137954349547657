import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router';
import Archive from './views/Archive.vue';
import Bio from './views/Bio.vue';
import Contact from './views/Contact.vue';
import Home from './views/Home.vue';
import Start from './views/Start.vue';

const isServer = typeof window === 'undefined';
const history = isServer ? createMemoryHistory() : createWebHistory();
const routes = [
    {
        path: '/github',
        beforeEnter() {location.href = 'http://github.com'}
    },
    {
        path: '/',
        name: 'Start',
        component: Start,
    },
    {
        path: '/archive',
        name: 'Archive',
        component: Archive,
    },
    {
        path: '/bio',
        name: 'Biography',
        component: Bio,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
    },
];

const router = createRouter({
    history,
    routes,
});

export default router;