<template>
  <div class="enter">
    <div class="content">

      <h1>vladlen babcinețchi</h1>
      <button>
        <router-link to="/home" class="nav-link">
          enter site
        </router-link>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Start"
}
</script>

<style scoped>

</style>