<template>
  <div class="bio">
    <Navbar/>
    <div class="container">
      <div class="bio-image">
        <img src="@/assets/images/siteContent/Portret.png" alt="Sculpture Vladlen">
        <p class="note">Vladlen Babcinețchi in his Atelier Studio.</p>
      </div>
      <div class="bio-content">
        <p class="quote">“Vladlen Babcineţchi goes beyond the symbolism that only he takes note of, he includes the
          Byzantine lesson, superimposed on Greek antiquity, filtered
          by the feeling of being, that draws its sap from the lands of his country and that, through its technique,
          universalizes a code that is not encrypted in matter but in the gaze of each of us.”</p>
        <p class="note">Art critic, Dr. Maria Bilașevschi</p>
        <p class="text">Born in the Republic of Moldova in 1983, sculptor Vladlen Babcinetchi captures the contemporary
          artistic landscape through the tenacity in which he approaches universal themes. This is brought to light
          through a unique language, rooted strongly in the turmoil of his birthplace. </p>
        <p class="text">Vladlen studied in Iasi, and it was here during high school that he began his journey as an
          artist and sculptor. During college he studied under sculptor Ilie Bostan, who was a professor at College of
          Arts Octav Bancilă. He then went on to complete a master programme at the National University of Arts George
          Enescu in Iași. Under the guidance of Professor Dr. Stan Mitu, Vladlen studied for his doctorate at ‘GH
          ASACHI’ University and following this he received an internship in Riga, Latvia and finally a Postdoctoral
          fellowship at Akaki Tsereteli University in Kutaisi, Georgia. </p>
        <p class="article">The artist has published his views on proportions in several articles as well as in a
          monograph for his doctoral thesis, culminating in the concept “Nautilus, a study of proportions on the
          movement of the human body”.</p>
        <p class="text">In the field of sculpture, he experiments with new techniques and his own language of creation,
          which he has exhibited to the public in Chișinau, Iași and Lisbon. He is a UAP Member of the Republic of
          Moldova and of the UAP Romania, and creator of several public works housed at institutions in Romania, the
          Republic of Moldova and Russia.</p>
        <p class="text">He is recognized as a skilled portraitist, his work being appreciated for his personal
          expressiveness and vibration. But it is the strength and energy with which he approaches his monumental works
          that makes this field the artist's main vocation. His monuments, such as of Ștefan Cel Mare, in Tomești,
          Romania, and of the poet Ovidiu, in the city bearing his name, showcase his talent as a composer and creator
          of timeless spaces filled with symbolism.</p>
        <p class="text">Vladlen Babcinețchi is 38 years old and lives and creates in Iași, Romania.</p>


      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar'

export default {
  name: "Biography",
  components: {
    Navbar
  }
}
</script>

<style scoped>

</style>