<template>
  <div class="gallery">
    <Navbar/>
    <div class="container d-flex flex-column">
      <div class="d-flex flex-wrap justify-content-start m-2">

        <div v-for="(image, index) in archive"
             :key="index"
             :style="{ backgroundImage: 'url(' + image.src + ')' }"
             class ="image"
             @click="showLightbox(index)"
        >

        </div>
        <!-- all props & events -->
        <vue-easy-lightbox
            scrollDisabled
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="archive"
            :index="index"
            @hide="handleHide"
        ></vue-easy-lightbox>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  name: "Archive",
  components: {
    Navbar,
    VueEasyLightbox,
  },
  data() {
    return {
      visible: false,
      index: 0, // default: 0
      archive: [
        {
          title: "Category I _ Portraits & Busts",
          src: require("@/assets/images/gallery/cat1/cat1.png"),
        },
        {
          title: "Queen Marie of Romania _ 2016 _ Queen Marie Library _ Tomesti _ Iasi",
          src: require("@/assets/images/gallery/cat1/Queen.jpg"),
        },
        {
          title: "Col. Michael Carrington _ 2019 _ Heritage of Nobrega Foundation _ Florence",
          src: require("@/assets/images/gallery/cat1/Carrington1.jpg"),
        },
        {
          title: "Mihai I _ 2020 _ The heritage of National Athenaeum _ Iasi",
          src: require("@/assets/images/gallery/cat1/Mihai.jpg"),
        },
        {
          title: "Brancusi _ 2016 _ The heritage of National Athenaeum _ Iasi",
          src: require("@/assets/images/gallery/cat1/Brancusi.jpg"),
        },
        {
          title: "Celibidache _ 2015 _ destroyed by the author ",
          src: require("@/assets/images/gallery/cat1/Celibidache.jpg"),
        },
        {
          title: "Labis _ 2017 _ Private collection _ Iasi",
          src: require("@/assets/images/gallery/cat1/Labis.jpg"),
        },
        {
          title: "Eminescu _ 2007 _ The heritage of Romanian Embassy _ Moscow",
          src: require("@/assets/images/gallery/cat1/Eminescu.jpg"),
        },
        {
          title: "Fondane _ 2017 _The patrimony of the Foreign Affairs Ministry of France _  French Cultural Institute _ Iasi",
          src: require("@/assets/images/gallery/cat1/Fondane.jpg"),
        },
        {
          title: "Iorga _ 2017 _ Private collection Iasi",
          src: require("@/assets/images/gallery/cat1/Iorga.jpg"),
        },
        {
          title: "Ferdinand I _ 2018 _ Tomesti _ Iasi",
          src: require("@/assets/images/gallery/cat1/Ferdinand.jpg"),
        },
        {
          title: "Bratianu _ 2021 _ Bratianu Street _ Iasi ",
          src: require("@/assets/images/gallery/cat1/Bratianu.jpg"),
        },
        {
          title: "Constantin Stere _ 2015 _ Private collection _ Brasov ",
          src: require("@/assets/images/gallery/cat1/Stere.jpg")
        },
        {
          title: "Carrington _ 2019 _ Private collection _  The heritage of Nobrega Foundation _ London",
          src: require("@/assets/images/gallery/cat1/Carrington.jpg"),
        },
        {
          title: "Queen Marie of Romania _ 2019 _ Tomesti _ Iasi",
          src: require("@/assets/images/gallery/cat1/Queen2.jpg"),
        },
        {
          title: "Category II _ Statuettes",
          src: require("@/assets/images/gallery/cat2/cat2.png"),
        },
        {
          title: "Mihai I King of Romania _ 2020 _ The heritage of National Athenaeum _ Iasi",
          src: require("@/assets/images/gallery/cat2/MihaiI.jpg"),
        },
        {
          title: "Mihai I King of Romania _ 2020 _ The heritage of National Athenaeum _  Iasi",
          src: require("@/assets/images/gallery/cat2/MihaiI-2.jpg"),
        },
        {
          title: "Ferdinand I _ 2017 _ Queen Marie Library _ Tomesti _ Iasi",
          src: require("@/assets/images/gallery/cat2/FerdinandI.jpg"),
        },
        {
          title: "Hyperion _ 2016 _ Private collection _ London",
          src: require("@/assets/images/gallery/cat2/Hyperion.jpg"),
        },
        {
          title: "Nautilus  _Detail _ 2018 _ The collection of the author",
          src: require("@/assets/images/gallery/cat2/Nautilus.jpg"),
        },
        {
          title: "Category III _ Statues",
          src: require("@/assets/images/gallery/cat3/cat3.png"),
        },
        {
          title: "Glossa _ 2020 _ The heritage of House of Culture Mihai Ursachi _ Ias.jpg",
          src: require("@/assets/images/gallery/cat3/Glossa.jpg")
        },
        {
          title: "Stefan the Great and Holy _ 2013 _ Tomesti _ Iasi",
          src: require("@/assets/images/gallery/cat3/Stefan.jpg")
        },
        {
          title: "Pieta _ 2021 _The heritage of Saint Parascheva Cathedral _ Voluntari",
          src: require("@/assets/images/gallery/cat3/Pieta.jpg")
        },
        {
          title: "The Silence _ 2020 _ Private collection _ Neptun",
          src: require("@/assets/images/gallery/cat3/Silence.jpg")
        },
        {
          title: "Ovidius ensemble _ 2018 _ Ovidiu City",
          src: require("@/assets/images/gallery/cat3/Ovidius.jpg")
        },
        {
          title: "Saint George _ Spirit and Memory ensemble _ 2016 _ Tomesti _ Iasi",
          src: require("@/assets/images/gallery/cat3/SaintGeorge.jpg")
        },
        {
          title: "Spirit and Memory ensemble _ 2016 _ Tomesti _ Iasi",
          src: require("@/assets/images/gallery/cat3/Spirit.jpg")
        },
        {
          title: "Category IV _ Exhibitions",
          src: require("@/assets/images/gallery/cat4/cat4.png"),
        },
        {
          title: "Fragment of an Angel _ 2021 _ Palace of Culture exhibition _ Iasi",
          src: require("@/assets/images/gallery/cat3/Angel2.jpg")
        },
        {
          title: "Fragment of an Angel _ 2021 _ Tomesti _ Iasi",
          src: require("@/assets/images/gallery/cat3/Angel1.jpg")
        },
        {
          title: "Corinthian _ 2020 _ Private collection _ Neptun ",
          src: require("@/assets/images/gallery/cat3/Corinthian.jpg")
        },
        {
          title: "Fragment of an Angel _ 2019 _ Private collection _ Iasi",
          src: require("@/assets/images/gallery/cat4/Angel10.jpg"),
        },
        {
          title: "Fragment of an Angel _ 2019 _ Exhibition _ Iasi",
          src: require("@/assets/images/gallery/cat4/Angel4.jpg"),
        },
        {
          title: "Self portrait _ 2019 _ Private collection _ Bucharest",
          src: require("@/assets/images/gallery/cat4/Angel6.jpg"),
        },
        {
          title: "Fragment of an Angel _ Sphynx _ 2022 _ 2020 _ Private collection _ Bucharest",
          src: require("@/assets/images/gallery/cat4/Angel3.jpg"),
        },
        {
          title: "Fragment of an Angel _ Sphynx _ 2021 _ Private collection _ Bucharest",
          src: require("@/assets/images/gallery/cat4/Angel1.jpg"),
        },
        {
          title: "Sphynx _ 2021 _ Private collection _ Bucharest(2)",
          src: require("@/assets/images/gallery/cat4/Angel8.jpg"),
        },
        {
          title: "Sphynx _ 2021 _ Private collection _ Bucharest",
          src: require("@/assets/images/gallery/cat4/Angel9.jpg"),
        },
        {
          title: "Fragment of an Angel _ 2021 _ Private collection _ Bucharest.jpg",
          src: require("@/assets/images/gallery/cat4/Angel11.jpg"),
        },
        {
          title: "Fragment of an Angel _ Sphynx _ 2021 _ Private collection _ Bucharest",
          src: require("@/assets/images/gallery/cat4/Angel2.jpg"),
        },
        {
          title: "Sphynx _ 2020 _ 2022 _ Private collection _ Bucharest",
          src: require("@/assets/images/gallery/cat4/Angel7.jpg"),
        },
        {
          title: "Fragment of an Angel _ Detail _ 2021 _ Private collection _ Bucharest",
          src: require("@/assets/images/gallery/cat4/Angel12.jpg"),
        },
        {
          title: "INGER DUBLU",
          src: require("@/assets/images/gallery/cat4/Angel5.jpg"),
        },
        {
          title: "Category V _ Architecture",
          src: require("@/assets/images/gallery/cat5/cat5.png"),
        },
        {
          title: "The Summer Altar _ 2021 _ Poglet Monastery _ Bacau County",
          src: require("@/assets/images/gallery/cat5/Altar.jpg")
        },
        {
          title: "Poglet Cross _ 2019 _ Poglet Monastery _ Bacau County",
          src: require("@/assets/images/gallery/cat5/Poglet.jpg")
        },
        {
          title: "Chandelier _ 2021 _ Collection of the author",
          src: require("@/assets/images/gallery/cat5/Chandelier.jpg")
        },
        {
          title: "Museum of Heroes _ Project _ 2017 _ Iasi County",
          src: require("@/assets/images/gallery/cat5/Museum.jpg")
        },
        {
          title: "Category VI _ Religious",
          src: require("@/assets/images/gallery/cat6/cat6.png"),
        },
        {
          title: "Seraph _ 2014 _ Private collection _ Bucharest",
          src: require("@/assets/images/gallery/cat6/Seraph.jpg")
        },
        {
          title: "Babel _ 2014 _ Private collection _ Bucharest",
          src: require("@/assets/images/gallery/cat6/Babel.jpg")
        },
        {
          title: "The calling of the angel _ 2014 _ The heritage of the Nobrega Foundation _ Florence",
          src: require("@/assets/images/gallery/cat6/Angelcall.jpg")
        },
        {
          title: "Exodus _ 2016 _ Collection of the author",
          src: require("@/assets/images/gallery/cat6/Exodus.jpg")
        },
        {
          title: "Parable of the blind _ 2016 _ Collection of the author _ Iasi ",
          src: require("@/assets/images/gallery/cat6/Parable.jpg")
        },
        {
          title: "Decorative element _ 2021 _ Private collection _ Timisoara",
          src: require("@/assets/images/gallery/cat6/Decor.jpg")
        },
        {
          title: "Holy Announcement _ 2021 _ The Heritage of Poglet Monastery _ Bacau County",
          src: require("@/assets/images/gallery/cat6/Holy.jpg")
        },
        {
          title: "Jesus _ 2015 _ Private collection _ Iasi",
          src: require("@/assets/images/gallery/cat6/Jesus.jpg")
        },
        {
          title: "Torso _ 2017 _ Private collection _ Iasi",
          src: require("@/assets/images/gallery/cat6/Torso.jpg")
        },
        {
          title: "Saint George _ 2019 _ Private collection _ Ovidiu ",
          src: require("@/assets/images/gallery/cat6/George.jpg")
        },
        {
          title: "Category VII _ Small sculpture",
          src: require("@/assets/images/gallery/cat7/cat7.png"),
        },
        {
          title: "Fragment of an angel _ 2020 _ Collection of the author",
          src: require("@/assets/images/gallery/cat7/Fragment.jpg")
        },
        {
          title: "Hermit _ 1996 _ Private collection Iasi",
          src: require("@/assets/images/gallery/cat7/Hermit.jpg")
        },
        {
          title: "Muse _ 2019 _ Private collection _ Chisinau",
          src: require("@/assets/images/gallery/cat7/Muse.jpg")
        },
        {
          title: "Detail _ 2008 _ Private collection _ Constanta",
          src: require("@/assets/images/gallery/cat7/Detail.jpg")
        },
        {
          title: "Nude _ 2009 _ Private collection _ Iasi",
          src: require("@/assets/images/gallery/cat7/Nude.jpg")
        },
        {
          title: "The wolf lady _ 2022 _ Private collection _ Iasi",
          src: require("@/assets/images/gallery/cat7/Wolf.jpg")
        },
        {
          title: "Spring  _ 2010 _ Private collection _ Bad Kissingen",
          src: require("@/assets/images/gallery/cat7/Spring.jpg")
        },
        {
          title: "Statuette _ Private collection _ Iasi",
          src: require("@/assets/images/gallery/cat7/Nude2.jpg")
        },
        {
          title: "The morning _ 2022 _ Collection of the author",
          src: require("@/assets/images/gallery/cat7/Morning.jpg")
        },
        {
          title: "Zamolxis _ 2016 _ Private collection _ Iasi",
          src: require("@/assets/images/gallery/cat7/Zamolxis.jpg")
        },
      ]
    }
  },

  methods: {
    showLightbox(index) {
      this.index = index
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },

  }
}
</script>

