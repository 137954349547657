<template>
  <div>
    <Navbar/>
    <b-carousel id="homeSlider" class="carousel"
                v-model="slide"
                :interval="2000"
                indicators
                no-hover-pause
    >
      <b-carousel-slide class="carousel-item active">
        <img src="@/assets/images/siteContent/Home1m1.png" class="carousel-image1m" alt="Sculpture Vladlen">
        <img src="@/assets/images/siteContent/Home.png" class="w-100 carousel-image1" alt="Sculpture Vladlen">
      </b-carousel-slide>
      <b-carousel-slide class="carousel-item">
        <img src="@/assets/images/siteContent/Home2m.png" class="carousel-image2m" alt="Sculpture Vladlen">
        <img src="@/assets/images/siteContent/Home2.png" class="w-100 carousel-image2" alt="Sculpture Vladlen">
      </b-carousel-slide>
      <b-carousel-slide class="carousel-item">
        <img src="@/assets/images/siteContent/Home3m.png" class="carousel-image3m" alt="Sculpture Vladlen">
        <img src="@/assets/images/siteContent/Home3.png" class="w-100 carousel-image3" alt="Sculpture Vladlen">
      </b-carousel-slide>
    </b-carousel>
    <div class="bar">
      <p class="bar-block"></p>
      <h2 class="bar-text">Sculpture</h2>
    </div>
    <Social/>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import Social from '../components/Social';

export default {
  name: "Home",
  components: {
    Navbar,
    Social
  },
  data() {
    return {
      slide: 0,
      sliding: null
    }
  },
  mounted() {
    this.slideStart()
  },
  methods: {
    slideStart() {
      this.sliding = true
    }

  }


}
</script>

<style scoped>

</style>