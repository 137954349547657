import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import BootstrapVue3 from 'bootstrap-vue-3'
import './sass/style.scss'

const app = createApp(App)
    app.use(router)
        .use(BootstrapVue3)
        .mount('#app');
