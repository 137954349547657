<template>
  <nav class="navbar navbar-expand-md">
    <div class="container-fluid">
      <router-link to="/home" class="navbar-brand">
        <img src="../assets/images/logo.png" alt="Vladlen-Babcinetchi-logo">
      </router-link>
      <router-link to="/home" class="navbar-brand-mobile">
        <img src="../assets/images/logoMobile.png" alt="Vladlen-Babcinetchi-logo">
      </router-link>
      <div class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <div class="navbar-toggler-icon">&#9776;</div>
      </div>
      <div class="collapse navbar-collapse ml-auto" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/home" class="nav-link">
              Home
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/bio" class="nav-link">
              Biography
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/archive" class="nav-link">
              Archive
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact" class="nav-link">
              Contact
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar"
}
</script>

<style scoped>

</style>