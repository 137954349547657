export const dictionary = {

};

export const dataset = {
    address: 'Atelier Vladlen Babcinețchi',
    logo: './assets/images/logo.png',
    mail: 'babcinetchiv@yahoo.com',
    mailTo: 'mailto:' + 'babcinetchiv@yahoo.com',
    phone: '+40 XXX XXXXXX',
    phoneHref: 'tel:' + 'phone',
    Instagram: 'https://instagram.com/vladlenbabcinetchi?igshid=YmMyMTA2M2Y=',
    Facebook: 'https://www.facebook.com/vladlenbabcinetchi/',
    Linkedin: 'https://www.linkedin.com/in/babcinetchi-vladlen-10438096',

};