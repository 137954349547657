<template>
  <div>
    <Navbar/>
    <div class="contact-page">
      <p>{{ dataset.address }}</p>
      <p>{{ dataset.mail }}</p>
      <Social/>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import Social from '../components/Social';
import {dataset} from "@/variables";

export default {
  name: "Contact",
  components: {
    Navbar,
    Social
  },
  data() {
    return {
      dataset,
    }
  }
}
</script>

<style scoped>

</style>